@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
	font-family: 'logikbold';
	src: url('../assets/fonts/logik_bold-webfont.woff2') format('woff2'),
		url('../assets/fonts/logik_bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'logikwide_bold';
	src: url('../assets/fonts/logik_wide_bold-webfont.woff2') format('woff2'),
		url('../assets/fonts/logik_wide_bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

html {
	font-size: 62.5%;
}

body {
	font-family: 'Rubik', sans-serif;
	font-size: 1.5rem;
	color: #8f93a5;
	background-color: #F5F5F5;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
	max-width: 1220px;
}

.header-bg {
	background-color: #57C483;
	width: 100%;
	z-index: 1;
	top: 0;
}

.searchBarSection {
	width: '100%';
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
}

.nave-section {
	position: relative;
	z-index: 1;
}

.nave-section .navbar {
	background-color: transparent !important;
	padding: 0;
}

.nave-section .navbar {
	font-size: 2 rem;
}

.navbar-brand {
	font-size: 2.5rem;
	font-weight: 700;
	text-transform: uppercase;
}

.navbar-expand-sm {
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-ms-flex-pack: space-between;
	justify-content: space-between;
}

.navbar-collapse {
	flex-grow: inherit;
}

.navbar #navbarsExample03 .navbar-nav li {
	padding: 0 1.5rem;
}

.navbar #navbarsExample03 .navbar-nav li a {
	font-size: 2.2rem;
	color: #fff;
	font-weight: 700;
}

.search-st i {
	color: #fff;
}

.nave-section {
	position: relative;
	z-index: 1;
	padding: 3.8rem 0;
}

.fixed-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: .5s ease-in-out;
}

.nave-section .navbar.fixed-header {
	background-color: #000 !important;
	transition: .5s ease-in-out;
}

.banner-section {
	background-color: #57C483;
	overflow: hidden;
}

.banner-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.banner-section p {
	font-size: 32px;
	color: #000;
	line-height: normal;
}

.banner-section h1 {
	font-size: 50px;
	color: #000;
	font-weight: 700;
}

.banner-text {
	width: 65%;
}

.banner-section h1 {
	font-size: 5rem;
	color: #000;
	font-weight: 700;
	margin-bottom: 13px;
}

.banner-section p {
	font-size: 3.2rem;
	color: #000;
	line-height: normal;
}

.button {
	background: #fff;
	padding: 1.5rem 3rem;
	border-radius: 16rem;
	font-size: 2.4rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #000;
	text-decoration: none;
	border: none;
}

.button:hover {
	background: #000;
	color: #fff;
	text-decoration: none;
}

.banner-img {
	width: 30%;
	height: 460px;
}

.mrg-10 {
	margin-bottom: 40px;
}

.report-name span {
	font-size: 24px;
	font-weight: 600;
	color: #000;
	display: block;
}

.report-section h2 {
	font-family: 'Rubik', sans-serif;
	color: #660777;
}

.reports {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.rp-box {
	width: 23%;
	background: #fff;
	border-radius: 15px;
	padding: 2rem 3rem;
	overflow: hidden;
	margin-bottom: 3rem;
}

.report-name {
	font-weight: 600;
	color: #A3A3A3;
	font-size: 1.6rem;
	margin: 2rem 0;
}

.report-name span {
	font-size: 2.4rem;
	font-weight: 600;
	color: #000;
	display: block;
}

.report-name {
	font-weight: 600;
	color: #A3A3A3;
	font-size: 1.6rem;
	margin: 2rem 0;
}

.spacing {
	padding: 9rem 0;
}

.bx-last {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 100px;
}

.readmore {
	border: 2px solid #57C483;
	padding: 0.5rem 1rem;
	border-radius: 5px;
	color: #57C483;
	font-weight: 600;
	font-size: 1.8rem;
}

.readmore:hover {
	background-color: #57C483;
	color: #fff;
	text-decoration: none;
}

.sm-btn {
	color: #466FE0;
	font-weight: 800;
	text-align: center;
	font-size: 1.6rem;
	text-transform: uppercase;

}

.gray {
	color: #5D5D5D;
}

.red {
	color: #DB2323;
}

.cor-pattern {
	position: absolute;
	right: -28px;
	bottom: -28px;
}

.popup__content .cor-pattern {
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.report-img {
	text-align: center;
}

h2 {
	font-size: 5rem;
	color: #000000;
	text-align: center;
	font-weight: 600;
	margin-bottom: 6rem;
	font-family: 'logikbold';
}

.report-divider {
	text-align: center;
	padding-top: 8rem;
}

.report-img img {
	max-width: 100%;
	min-width: 84px;
}

.report-divider .button {
	background: #65c98d;
	color: #fff;
}

.green-bg {
	background-color: #57C483;
}

.stockpart {
	display: flex;
	justify-content: space-between;
}

.stockbox {
	width: 23%;
	overflow: hidden;
	padding: 3rem;
}

.smtitle {
	font-size: 2.8rem;
	color: #000000;
	text-align: center;
	line-height: 2rem;
	margin-bottom: 6rem;
	font-family: 'logikbold';
}

.smtitle:hover {
	color: #000;
	text-decoration: none;
}

.stockbox p {
	color: #D4FFB7;
	font-size: 1.8rem;
}

.stockbox svg {
	margin-bottom: 2.5rem;
	width: 85%;
	height: 132px;
}

.stockbox:first-child,
.sample-divider:first-child {
	padding-left: 0;
}

.stockbox:last-child,
.sample-divider:last-child {
	padding-right: 0;
}


.samplestock {
	display: flex;
	justify-content: space-between;
}

.sample-divider {
	font-size: 2.6rem;
	color: #660777;
	letter-spacing: 0;
	width: 32%;
	padding: 2.8rem;
}

.logik-wb {
	font-family: 'logikwide_bold';
	font-weight: 600;
}

.sm-smtitle {
	font-size: 4rem;
	color: #660777;
	text-align: center;
	line-height: 2rem;
	margin-bottom: 3rem;
	display: inline-block;
}

.smtitle:hover {
	text-decoration: none;
}

.stockbox p {
	color: #D4FFB7;
	font-size: 1.8rem;
}

.stockbox svg {
	margin-bottom: 2.5rem;
	width: 85%;
	height: 132px;
}

.sample-divider p {
	line-height: 3rem;
	margin-bottom: 7rem;
}

.sample-divider .button {
	background: #65c98d;
	color: #fff;
}

.cpd {
	padding: 7rem 0 0 0;
}

.sample-divider .button:hover {
	background: #000;
}



.testimonials {
	background-color: #660777;
}

.lead {
	font-size: 16.px;
}

.card {
	border: 0;
	margin: 10px;
	outline: none;
	cursor: pointer;
}

.slick-dots li button:before {
	color: #fff !important;
	font-size: 48px;
}

.card-body h3 {
	color: #000;
	align-items: left;
	font-weight: 600;
	margin: 40px 0px 0px 0px;

}

.cust_card_btn_div {
	margin: 0px 0px 50px;

}

.slick-dots li.slick-active button::before {
	opacity: 100;
	color: #57C483 !important;
}

.slick-dots li button::before {
	color: #D4FFB7 !important;
	font-size: 48px;
	opacity: 100;
}

.cust_card_btn {
	color: #fff;
	Background: #991B1E;
	padding: 16px 40px;
	position: absolute;
	right: 0px;
}

.card-body span {
	background: #991B1E;
	border-radius: 4px;
	padding: 10px 15px;
	color: #fff;
	position: relative;
	float: right;
	margin-bottom: 50px;
}

.card {
	background-color: transparent;
	border: none;
	border-radius: inherit;
}

.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.25rem;
	color: #fff;
}

.lead {
	font-size: 2.4rem;
}

.card-body figure {
	font-weight: 600;
	font-size: 2.4rem;
	text-align: center;
}

.testimonials .slick-next::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 100px;
	height: 100px;
	background-image: url(../assets/images/right-arow.svg);
	background-repeat: no-repeat;
}

.testimonials .slick-next::before {
	content: '';
	position: absolute;
	top: 0;
	left: 50px;
	width: 28px;
	height: 48px;
	background-image: url(../assets/images/right-arow.svg);
	background-repeat: no-repeat;
}

.testimonials .slick-prev::before {
	content: '';
	position: absolute;
	top: 0;
	right: 50px;
	width: 28px;
	height: 48px;
	background-image: url(../assets/images/left-arow.svg);
	background-repeat: no-repeat;
}

.slick-next {
	right: 0;
}

.footer-smo {
	font-size: 2.8rem;
	font-weight: 600;
}

.footer-smo a {
	color: #fff;
	padding: 0 3.5rem;
}

.footer-smo a:hover {
	color: #660777;
}

.copyright {
	font-size: 2.2rem;
	color: #fff;
	font-weight: 600;
}

.footer-smo {
	font-size: 2.8rem;
	font-weight: 600;
	padding: 6rem 0;
}




.searchbox {
	position: relative;
	min-width: 50px;
	width: 0%;
	height: 50px;
	float: right;
	overflow: hidden;

	-webkit-transition: width 0.3s;
	-moz-transition: width 0.3s;
	-ms-transition: width 0.3s;
	-o-transition: width 0.3s;
	transition: width 0.3s;
}

.searchbox-input {
	top: 0;
	right: 0;
	border: 0;
	outline: 0;
	background: #dcddd8;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0px 55px 0px 20px;
	font-size: 20px;
	color: red;
}

.searchbox-input::-webkit-input-placeholder {
	color: #d74b4b;
}

.searchbox-input:-moz-placeholder {
	color: #d74b4b;
}

.searchbox-input::-moz-placeholder {
	color: #d74b4b;
}

.searchbox-input:-ms-input-placeholder {
	color: #d74b4b;
}

.searchbox-icon,
.searchbox-submit {
	width: 50px;
	height: 50px;
	display: block;
	position: absolute;
	top: 0;
	font-family: verdana;
	font-size: 22px;
	right: 0;
	padding: 0;
	margin: 0;
	border: 0;
	outline: 0;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
	color: #dcddd8;
	background: #172b3c;
}

.searchbox-open {
	width: 100%;
}


.search-st {
	width: 200px;
	max-width: 300px;
	float: right;
	position: absolute;
	overflow: hidden;
	transition: all 0.2s ease-in-out;
	right: 0;
	border-radius: 25px;
}

.form-inline {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	max-width: var(--responsive--aligndefault-width);
	opacity: 0;
}

.search-st input {
	font-family: Arial;
	font-size: 22px;
	height: 100%;
	line-height: 96px;
	padding: 0px 20px;
	border: none;
	z-index: 999999;
	background: #fff;
	float: right;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
}

.search-st.open {
	width: 100%;
}

.search-st.open .form-inline {
	opacity: 100%;
}

.navbar-nav {
	padding-right: 80px;
}

.search-st.open a {
	background-color: #660777;
	width: 55px;
	height: 55px;
	float: right;
	z-index: 9999999;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.search-st a {
	width: 53px;
	height: 39px;
	float: right;
	z-index: 9999999;
	position: relative;
}

.search-st.open a svg {
	width: 30px;
}

.inner-banner {
	background: #660777;
}

.height {
	height: 650px;
}

.height2 {
	height: 250px;
}

.inner-banner .banner-text {
	width: 100%;
	text-align: center;
	color: #fff;
}

.inbanner-text {
	width: 90%;
	text-align: center;
	color: #fff;
	color: #fff;
	text-align: center;
	padding-left: 0;
	transform: translateY(-50%);
	top: 50%;
	bottom: auto;
	left: 0;
	right: 0;
	position: absolute;
	margin: auto;
}

.inbanner-text h1 {
	color: #fff;
	font-size: 10rem;
	margin-bottom: 4rem;
	font-weight: 900;
}

.inbanner-text p {
	color: #fff;
	font-size: 3.2rem;
}

.user-profile {
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
	margin-top: 10px;
}

.user-profile figure {
    width: 30px;
    height: 30px;
	align-items: center;
	justify-content: center;
    border-radius: 100%;
    background: #fff;
    overflow: hidden;
    text-align: center;
    display: flex;
  }

.btn-green {
	background: #65c98d;
	color: #fff;
	padding: 1.5rem 3rem;
	border-radius: 16rem;
	font-size: 2.4rem;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	border: none;
}

.btn-green:hover {
	background: #000;
	color: #fff;
}

.smt-title {
	font-size: 3.2rem;
	color: #660777;
	font-weight: 600;
	margin-bottom: 2rem;
}

.banner-divider {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	max-width: 100%;
}

.navbar-expand-sm .navbar-nav .dropdown-menu a {
	color: #fff !important;
	font-weight: normal !important;
	font-size: 1.5rem !important;
	padding: 0.3rem 2rem;
	font-weight: 500 !important;
	text-transform: uppercase;
}

.navbar-expand-sm .navbar-nav .dropdown-menu a:last-child {
	border-bottom: none;
}

.navbar-expand-sm .navbar-nav .dropdown-menu a:hover {
	background-color: transparent;
	color: #57C483 !important;
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
	left: 22px;
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
	padding: 0;
	padding: 1.1rem 0;
}

.mobile-nave {
	display: none;
}

.mobile-navstyle {
	background-color: #660777;
	position: fixed;
	width: 100%;
	z-index: 999;
	padding:0;
}

.menu-divider{padding: 6rem 9rem;}



.mobile-logo img {
	width: 100% !important;
}

.mobile-logo {
	text-align: center;
}

.mobile-navstyle .navbar-nav li a {
	font-size: 5.8rem;
	color: #D4FFB7;
	font-family: 'logikbold';
	text-transform: capitalize;
}

.mobile-navstyle .dropdown-toggle::after {
	display: inline-block;
	content: "";
	border: none;
	background-image: url(../assets/images/menu-arrow.svg);
	background-repeat: no-repeat;
	width: 23px;
	height: 27px;
	position: absolute;
	top: 13px;
	right: 0;
}

.mobile-navstyle .navbar-nav .dropdown-menu {
	position: static;
	float: none;
	display: block;
	background: transparent;
}

.mobile-navstyle .navbar-nav .dropdown-menu a {
	font-size: 4.5rem;
	color: #D4FFB7;
	font-family: 'Rubik', sans-serif;
	text-transform: uppercase;
}

.mobile-navstyle .navbar-nav .dropdown-menu {

	border: none;
	padding: 0;
	margin: 0;
}

.mobile-navstyle .navbar-nav.mr-auto {
	padding-right: 0;
}

.mobile-navstyle .navbar-nav.mr-auto li {
	border-bottom: 1px solid #d4ffb7;
	margin-bottom: 15px;
}

.navbar-toggler-icon {
	background-image: none !important;
	background-repeat: no-repeat;
}

.navbar-dark .navbar-toggler {
	border: none;
}

.mobile-nave button {
	width: 37px;
}

.mobile-nave button svg {
	width: 100%;
	height: auto;
}

.search-st {
	width: 62px;
	right: 0;
	/*display: flex;*/
}

.mobile-nave .navbar {
	display: inline-block;
}

.mobile-banner {
	display: none;
}

.mo {
	display: none;
}

.mobilestylelogo {
	display: none;
}

.dropdown-menu {
	background-color: #660777;
	border-radius: 8px 0 8px 8px;
}

.search-results {
	font-size: 24px;
	text-align: center;
	color: #D4FFB7;
	font-weight: bold;
	margin-bottom: 20px;
	border: 1px solid #D4FFB7;
	margin-left: 10%;
	margin-right: 10%;
}

.search-results span {
	font-size: 12px;
	font-weight: normal;
}


.black {
	color: #000 !important;
	font-weight: 600 !important;
}

.aout-banner {
	display: flex;
	justify-content: center;
	align-items: center;
}

.aout-banner figure span {
	display: inline-block;
	width: 100%;
	color: #000;
	padding-top: 1.2rem;
}

.border {
	border: 1px solid #000 !important;
	width: 67%;
	margin-left: 16.5%;
	margin-bottom: 10px;
	margin-top: 10px
}

.inbanner-text .normal-m {
	margin-bottom: 0;
}

.aout-banner figure img {
	width: 6.8rem;
}

.aout-banner figure {
	text-align: center;
}

.aout-banner figure span {
	display: inline-block;
	width: 100%;
	color: #000;
	padding-top: 1.2rem;
	font-size: 2rem;
}

.light-green {
	background-color: #D4FFB7;
}

.aboutdata {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ab-data p,
.user-details p,
.about-user p,
.venture p {
	font-size: 3rem;
	color: #000;
}

h3 {
	font-size: 4.8rem;
	color: #000;
	font-weight: 700;
}

.aboutdata {
	display: flex;
	justify-content: center;
	align-items: center;
}

.aboutimg {
	width: 50%;
}

.ab-data {
	width: 50%;
	padding-left: 7rem;
}

.aboutimg img {
	width: 100%;
}

.lavender-color {
	background-color: #660777;
}

.userdata {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	margin-bottom: 3rem;
}

.about-user p {
	color: #fff;
}

.user-details h3,
.user-details p {
	color: #fff;
	;
}

.user-img {
	border-radius: 100%;
	width: 85rem;
	overflow: hidden;
}

.user-img img {
	width: 100%;
}

.venture {
	text-align: center;
	margin-top: 9rem;
}

.vision-section {
	display: flex;
	justify-content: space-between;
}

.vis-box {
	text-align: center;
	width: 48%;
}

.vis-box span {
	width: 100%;
	display: block;
	font-family: 'logikbold';
	font-size: 5.5rem;
	color: #660777;
}

.vis-box img {
	margin: 1rem 0 1rem 0;
	width: 16rem;
}

.vis-box p {
	font-size: 2.4rem;
	color: #660777;
	font-weight: 500;
	width: 75%;
	margin: auto;
}

.dnlink img {
	width: 29rem;
}

.contact-divider {
	font-weight: 600;
	color: #000;
	font-size: 2rem;
}

.contact-divider span {
	font-weight: normal;
	width: 100%;
	display: block;
}

.contact-page h2 {
	font-family: 'Rubik', sans-serif;
}

.contact-details {
	display: flex;
	align-items: center;
	margin-bottom: 5rem;
}

.iconcity {
	width: 60px;
}

.contact-cp {
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-details a {
	color: #000;
}

.message-btn {
	color: #D4FFB7 !important;
	font-size: 1.5rem;
	text-transform: uppercase;
}

.lineheight {
	line-height: 2.5rem;
}

.iconcity {
	width: 78px;
	text-align: center;
}

.message-btn:hover {
	color: #000 !important;
}

.searchpage p br {
	display: none;
}

.error-message {
	text-align: center;
}

.msg-img {
	width: 40%;
}

.msgtext {
	display: block;
	width: 100%;
	font-size: 3rem;
	font-weight: 600;
	color: #fff;
}

.msgtext {
	display: block;
	width: 100%;
	font-size: 4rem;
	font-weight: 600;
	color: #fff;
	margin-bottom: 7rem;
}

.white-border {
	border-color: #fff;
	margin: 0;
	padding: 0;
}

.login-divider {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 288px;
	margin: auto;
}

.login-title {
	text-align: center;
	text-transform: uppercase;
	line-height: 1.5rem;
	margin-bottom: 3rem;
	color: #000;
}

.divider-part {
	background: #fff;
	display: flex;
	width: 280px;
	font-size: 1.5rem;
	font-weight: 600;
	padding: 2rem 2rem;
	border-radius: 5px;
	color: #000;
	align-items: center;
	margin-bottom: 2rem;
}

.divider-part figure {
	padding-right: 14px;
	margin-bottom: 0;
}

.login-title figure {
	font-size: 2rem;
	font-weight: 600;
}

.ft-signup {
	width: 100%;
	color: #fff;
	font-size: 1.6rem;
	line-height: 2rem;
	padding-top: 2.5rem;
}

.ft-signup a {
	color: #fff;
}

.ft-signup a:hover {
	color: #000;
}

.divider-part:hover {
	color: #000;
}



.popup {
	width: 100%;
	height: 100%;
	background: rgba(102, 7, 119, 0.7);
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	z-index: 999;
}

.popup .popup__content {
	width: 80%;
	overflow: auto;
	padding: 50px;
	background: white;
	color: black;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
}

.popup .popup__content .close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 20px;
	display: block;
	opacity: 100;
}

.popup .popup__content .close span {
	cursor: pointer;
	position: fixed;
	width: 20px;
	height: 4px;
	background: #ffffff;
}

.popup .popup__content .close span:nth-child(1) {
	transform: rotate(45deg);
}

.popup .popup__content .close span:nth-child(2) {
	transform: rotate(135deg);
}

.popup .popup__content {
	width: 250px;
	overflow: auto;
	padding: 70px 30px 40px 30px;
	background: #57C483;
	color: black;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
	border-radius: 10px;
}

.popup__content p {
	font-size: 1.7rem;
	color: #fff;
	text-align: left;
	line-height: 1.1;
}

.popup .btn-green {
	background: #fff;
	color: #000;
	padding: 1rem 2rem 0.8rem 2rem;
	border-radius: 16rem;
	font-size: 1.4rem;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	border: none;
	line-height: normal;
	margin: 1.7rem 0;
	display: inline-block;
}

.popup .btn-green:hover {
	background: #000;
	color: #fff;
}

.popup .ft-signup {
	padding-top: 0;
	text-align: left;
	font-size: 1.2rem;
}

.popup .ft-signup a {
	text-decoration: underline;
}

.premimu-shap {
	position: absolute;
	right: 0;
	bottom: 0;
}

.premimu-shap img {
	width: 55px;
}

.target {
	background: transparent;
	border: none;
	float: right;
	position: absolute;
	right: 32px;
	top: 46px;
}

.banner-img {
	position: relative;
}

.banner-img::before {
	position: absolute;
	top: 0;
	right: -101%;
	width: 225%;
	height: 100%;
	content: "";
	background-image: url(../assets/images/Home-Banner-img.png);
	background-repeat: no-repeat;
	background-size: 100%;
}
.smt-btn {
	line-height: 7.5rem;
	margin-top: 4.5rem;
}
.skyimg {
	position: relative;
	width: 100%;
	z-index: -1;
}

.skyimg::before {
	position: absolute;
	top: -158px;
	right: -126px;
	width: 100%;
	height: 410px;
	content: "";
	background-image: url(../assets/images/magglass.svg);
	background-repeat: no-repeat;
	background-size: 500px;
	background-position: right;
}

.mc-title{ font-size: 3.2rem; color: #fff; font-family: 'logikwide_bold';}
.cap-box p{ color: #fff; font-size: 2rem;}
.cap-divider {
	display: flex;
	justify-content: inherit;
	padding: 2.5rem 0;
}
.cap-box .rp-box {
	margin-right: 2rem;
}
.cap-box {
	margin-bottom: 10rem;
}
.sb-banner-title{font-size: 2rem; text-align: left;}
.sb-banner-title small{font-size:1.8rem; display: block;}
.inbanner-text.sbdiv {
	width: 68%;
	margin: auto;
	text-align: left;
}
.inbanner-text p.sb-banner-title {
	color: #fff;
	font-size: 3.2rem;
}
.smt-btn {
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: 600;
	border: 2px solid #57c483;
	border-radius: 100px;
	padding: 0.7rem 2rem;
	color: #fff;
}
.fill-c{ background-color: #57C483;}
.subscribe-main h2{font-family: 'Rubik', sans-serif; font-size: 4rem; text-transform: uppercase; line-height: 0.8;}
.subscribe-main h2 span{ display: inline-block; width: 100%; font-size: 1.8rem; font-weight: normal;}
.subscribe-plan {
	display: flex;
	justify-content: space-between;
}
.sb-planbox {
	background: #fff;
	width: 23%;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	opacity: 0.6;
}
.plan-title {
	background: #660777;
	padding: 2rem;
	text-align: center;
	color: #fff;
	font-weight: normal;
}
.plan-title span {
	display: block;
	font-family: 'logikwide_bold';
	font-size: 1.4rem;
}
.price-box {
	text-align: center;
	color: #000;
	font-size: 1.4rem;
	padding: 1rem 0;
	line-height: 1.2;
}
.price-box figure {
	font-weight: 600;
	font-size: 3rem;
}
.plan-space{ text-align: center; padding: 2rem;}
.plan-space {
	text-align: center;
	padding: 1rem 2rem 4rem 2rem;
}
.plan-space .btn-green {
	font-size: 1.5rem;
	padding: 1rem 4rem;
}
.detail-form{ margin-top: 10rem;}
.detail-form .form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	padding: 3rem 2rem;
	font-size: 2rem;
	font-weight: 400;
	line-height: 1.5;
	color: #fff;
	background-color: transparent;
	background-clip: padding-box;
	border: 1px solid #000;
	border-radius: 6px;
	/* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}
.detail-form .text-muted {
	color: #000 !important;
	font-size: 1.7rem;
}
.detail-form .button {
	padding: 0.8rem 2.5rem;
	border-radius: 16rem;
	font-size: 2.2rem;
}
.banner-space{ text-align: center; margin-top: 2rem;}
.detail-form  .form-group {
	margin-bottom: 2rem;
}

.smt-btn:hover {
	background: #000;
	border-color: #000;
	color: #fff;
}
.toplogo {
	background: #fff;
	display: flex;
	justify-content: space-evenly;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;

}
.toplogo img {
	margin: 0 19rem;
}
.filter-section .sm-btn{ background-color: #fff; }
.filter-section {
	text-align: center;
	padding: 3rem 0;
}
.filter-section .sm-btn {
	background-color: #fff;
	padding: 1rem 2rem;
	border-radius: 37px;
}
.filter-section {
	text-align: center;
	padding: 3rem 0;
}
.filter-pera figure span {
	display: block;
}
.filter-pera {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 293px;
	margin: 2.3rem auto auto auto;
}
.filter-pera figure {
	width: 35%;
	color: #fff;
	font-weight: 600;
}
.perelax-menu ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
}
.perelax-menu ul li a {
	color: #000;
	font-size: 1.8rem;
	font-weight: 300;
	position: relative;
}
.perelax-menu ul li a::before {
	position: absolute;
	top: 0;
	right: -32px;
	width: 20px;
	height: 20px;
	content: "";
	background-image: url(../assets/images/Polygon.png);
	background-repeat: no-repeat;
}
.perelax-menu {
	padding: 2rem;
}
.watermark {
	position: fixed;
	right: 0;
	left: 0;
	margin: auto;
	width: 280px;
	top: 50%;
}
.dpr-box span {
	font-size: 2.5rem;
	font-weight: 700;
	color: #660777;
}
.overview-section {
	padding: 3rem 0;
}
.buybtn{color: #466FE0;
	font-weight: 800;
	text-align: center;
	font-size: 1.6rem;
	text-transform: uppercase;}
	.dpr-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 2rem;
		padding-top: 3rem;
	}
	.report-details li {
		list-style: disc;
		color: #000;
		font-size:2rem;
		margin-bottom: 1.8rem;
	}
	.report-details {
		margin: 0;
		padding: 2rem;
	}
	.report-details li a{ color: #57C483; font-weight: 600;}
	.report-details li a:hover{ color: #000;}
	.whitebg{ background-color: #fff;}


	.overview-section .table .table-header {
		background: #57C483;
		color: #fff;
		text-transform: uppercase;
	}
	  .overview-section .table .table-header .cell {
		padding: 20px;
	  }
	  @media screen and (max-width: 640px) {
		.overview-section .table {
		  overflow-x: auto;
		  display: block;
		}
		.overview-section .table .table-header .cell {
		  padding: 20px 5px;
		}
	  }
	 .overview-section tr:nth-child(odd) {background-color: #E6F6ED;}
	 .basic {
		background: #D4FFB7;
		border-radius: 0 20px 20px 20px;
		padding: 4rem;
		color: #660777;
		margin: 5rem 0;
		width:100%;
	}

	.basic span {
		font-weight: 600;
		text-transform: uppercase;
		font-size: 2rem;
	}
	.basic p em {
		font-size: 5rem;
		font-style: normal;
		font-weight: 600;
		display: block;
	}
	.purpal{ border-color: #660777;}
	.purpal {
		border-color: #660777;
		margin: 2rem 0;
	}
	.overview-section p{ color: #000;}
	.analysis {
		display: inline-block;
		margin: 21px 0;
	}
	.con-divider {
		background: #57C483;
		color: #000;
		display: flex;
		justify-content: center;
		border-top: 2px solid #d4ffb7;
		border-bottom: 2px solid #d4ffb7;
		padding: 2rem 0;
		margin: 3rem 0 28px 0;
	}
	.con-divider figure {
		text-align: center;
		width: 50%;
		border-right: 2px solid #d4ffb7;
	}
	.con-divider figure span {
		display: inline-block;
		width: 100%;
		font-weight: 600;
		font-size: 5rem;
	}
	.conclusion {
		background: #57C483;
		border-radius: 0 20px 20px 20px;
		padding: 4rem;
		color: #000;
		width: 407px;
		margin-top: 2rem;
		margin-bottom: 3rem;
	}
		
		.con-divider figure {
	text-align: center;
	width: 50%;
	border-right: 2px solid #d4ffb7;
	line-height: 1.2;
	margin: 0;
}
.con-divider figure:last-child{ border: none;}
.smtb {
	display: block;
	font-weight: 600;
}
.ppr{color: #660777 !important; text-align: center;}
.sb-planbox.active {
	opacity: 1;
}
.vis-box p {
	padding-bottom: 8px;
}
.basic-auto {
	width: 300px;
	margin: auto;
}
.pattern{ background-image: url(../assets/images/ptn-bg.png);}
.analysis img {
	width: 45%;
	margin-right: 3rem;
	border: 1px solid #b9b9b9;
}
.analysis {
	display: flex;
	margin: 21px 0;
}
.contact-right img {
	width: 69%;
}
.contact-right {
	text-align: right;
}

.profile-page {
	overflow: hidden;
}

.form-input {
	border-radius: 10px;
	padding: 5px;
	margin-left: 10px;
	background-color: rgba(255,255,255,0.3);
  	transition: 0.3s all;
	height: 60px;
	width: 60%;
	margin-top: 25px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}


.form-input input {
	width: 100%;
	height: 46px;
	position: relative;
	padding: 0px 16px;
	border: none;
	border-radius: 4px;
	font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	background-color: transparent;
	color: #282828;
	outline: none;
	box-shadow: 0px 4px 20px 0px transparent;
	transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
	-webkit-appearance: none;
	margin-left: 30px;
	margin-top: 5px;
}

.country-code {
	position: absolute; 
	color: #282828;
	margin-top: 17px; 
	margin-left: 10px;
}