@media screen and (max-width: 1700px) {
  .banner-img {
    width: 30%;
  }

  .banner-img::before {
    right: -73%;
  }
}

@media screen and (max-width: 1500px) {
  .banner-img::before {
    right: -47%;
    width: 190%;
  }
}


@media screen and (max-width: 1350px) {
  .banner-img::before {
    right: -25%;
    width: 170%;
    top: 6rem;
  }

  .testimonials .slick-prev::before,
  .testimonials .slick-next {
    right: 40px;
  }

  .testimonials .slick-next::before,
  .testimonials .slick-prev {
    left: 20px;
  }

  .card-body {
    padding: 1.25rem 13rem;
  }
}

@media screen and (max-width: 1130px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1024px;
  }

  html {
    font-size: 52.5%;
  }

  .slick-prev {
    left: 13px;
  }

  .testimonials .slick-next::before {
    left: 15px;
  }

  .contact-right img {
    width: 100%;
  }

  .divider-part {
    font-size: 1.9rem;
  }
  .country-code {
    font-size: 15px;
  }
}

@media screen and (max-width: 1024px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 800px;
  }

  html {
    font-size: 42.5%;
  }

  .banner-img::before {
    right: -132px;
    width: 199%;

  }

  .banner-img {
    height: 345px;
  }

  .coin-img img {
    width: 100%;
  }

  .height {
    height: 500px;
  }

  .contact-divider {
    font-size: 2.5rem;
  }

  .message-btn {
    font-size: 2rem;
  }

  .divider-part {
    font-size: 2.3rem;
  }

  .login-title figure {
    font-size: 3rem;
  }

  .login-title {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }

  .popup__content p {
    font-size: 2.7rem;
  }

  .popup .ft-signup {
    font-size: 1.8rem;
  }

  .popup .btn-green {
    font-size: 2.0rem;
    margin: 2.7rem 0;
  }
  .skyimg::before {
    background-size: 400px;
    right: -59px;
    top: -139px;
  }
  .sb-planbox {
    width: 24%;
  }

 
}

@media screen and (max-width: 991px) {
  .search-x.height {
    height: 585px;
  }
  .skyimg::before {
    background-size: 255px;
    right: -47px;
    top: -107px;
  }
  .mc-title {
    font-size: 4.2rem;
  }
  .cap-box p {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 90%;
  }

  .banner-img::before {
    right: -73px;
  }

  .navbar-brand img {
    width: 180px;
  }

  html {
    font-size: 35.5%;
  }

  .report-img {
    text-align: center;
    height: 57px;
    display: flex;
    align-items: center;
  }

  .testimonials .slick-prev::before,
  .testimonials .slick-next::before {
    width: 21px;
    height: 35px;
    background-size: 100%;
  }

  .divider-part {
    font-size: 2.9rem;
  }

  .ft-signup {
    font-size: 2.6rem;
    line-height: 3rem;
  }
  .subscribe-plan {
    flex-wrap: wrap;
  }
  .sb-planbox {
    width: 44%;
    margin-bottom: 5rem;
  }
  .plan-title {
    font-size: 2.5rem;
  }
  .plan-title span {
    font-size: 3rem;
  }
  .price-box {
    font-size: 3rem;
}
.price-box figure {
	font-size: 7rem;
}

}


@media screen and (max-width: 767px) {

  .banner-img::before {
    display: none;
  }

  .profile-page {
    margin-top: 0px;
  }

  .subscribe-main {
    margin-top: 0px;
  }

  .banner-img {
    height: auto;
  }

  .cor-pattern img {
    width: 67px;
  }

  .reports,
  .stockpart {
    flex-wrap: wrap;
  }

  .rp-box,
  .stockbox {
    width: 48%;
    margin-bottom: 4rem;
  }

  .bx-last {
    height: 60px;
  }

  .button {
    padding: 2.56rem 5rem;
    font-size: 3.4rem;
  }

  .stockbox {
    width: 48%;
    padding: 1rem;
  }

  .stockbox svg {
    width: 68%;
    height: 80px;
  }

  .stockbox {
    padding: 1rem;
  }

  .smtitle {
    font-size: 3rem;
  }

  .samplestock {
    flex-wrap: wrap;
  }

  .sample-divider {
    width: 100%;
  }

  .sample-divider {
    padding: 0;
  }

  .inbanner-text h1 {
    font-size: 7rem;
  }

  .btn-green {
    font-size: 3.4rem;
  }

  .height {
    height: 400px;
  }

  .navbar-brand img {
    width: 135px;
  }

  .inbanner-text p br {
    display: none;
  }

  .inbanner-text {
    text-align: left;
  }

  .inbanner-text h1 {
    margin-bottom: 0;
  }

  .mobile-nave {
    display: flex;
    position: fixed;
    z-index: 1;
    padding: 2rem 0 1rem 0;
    width: 73%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 20px;
    background: #660777;
    border-radius: 25px;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid #fff;
  }

  .bg-dark {
    background-color: transparent !important;
  }

  .nave-section {
    display: none;
  }

  .banner-section {
    margin-top: 0px;
  }

  .mobile-nave .search-st a {
    width: 41px;
    height: 39px;
    float: right;
    z-index: 9999999;
    position: relative;
  }

  .search-st {
    display: inline-block;
    width: 47px;
  }

  .search-st.open a svg {
    width: auto;
  }

  .user-profile figure {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    overflow: hidden;
    text-align: center;
    display: flex;
  }

  .mobile-nave button svg {
    width: 80%;
  }

  .user-profile {
    display: inline-block;
    margin-top: 0px;
  }

  .logo-icon img {
    width: 75%;
  }

  .logo-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
  }

  .z-index {
    z-index: 9;
  }

  .dp-banner {
    display: none;
  }

  .mobile-banner {
    display: block;
  }

  .mobilestylelogo {
    background: #57c483;
    padding: 4rem 0;
    text-align: center;
  }

  .mobile-logo {
    margin-bottom: 5rem;
  }

  .sample-divider .button {
    padding: 1rem 3rem;
  }

  .sample-divider p {
    margin-bottom: 3rem;
  }

  .mobilestylelogo {
    display: block;
  }

  .secongpage .mobilestylelogo {
    background: #660777;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: transparent;
  }

  .mobile-navstyle .navbar-nav .dropdown-menu a:hover {
    color: #FFF;
  }

  .search-st.open {
    width: auto;
  }

  .search-st {
    position: initial;
  }

  .search-st a svg {
    width: 30px;
  }

  .search-st.open a svg {
    width: 30px;
  }

  .search-st input {
    border: 1px solid #660777;
    border-radius: 20px 0 0 20px;
  }

  footer {
    padding-bottom: 7rem;
  }

  .secongpage .mobilestylelogo.aboutlogo {
    background-color: #57c483;
  }

  .aboutdata {
    display: inline-block;
  }

  .aboutimg,
  .ab-data {
    width: 100%;
    padding-left: 0;
  }

  .vision-section {
    display: inline-block;
  }

  .vis-box {
    margin-bottom: 10rem;
    width: 100%;
  }

  .vis-box:last-child {
    margin-bottom: 0rem;
  }

  .contact-right {
    display: none;
  }

  .contact-divider {
    font-size: 3.2rem;
  }

  .message-btn {
    font-size: 2.5rem;
  }

  .lineheight {
    line-height: 3.5rem;
  }

  footer .text-center {
    text-align: left !important;
  }

  .searchpage p br {
    display: block;
  }

  .searchpage .inbanner-text p {
    font-size: 4rem;
  }

  .navbar-expand-sm .navbar-toggler {
    display: block;
  }

  .msg-img {
    width: 100%;
  }

  .popup .ft-signup {
    font-size: 2.1rem;
  }

  .mobilestylelogo img {
    width: 200px;
  }

  .mobilestylelogo {
    padding: 6.3rem 0 4rem 0;
  }

  .header-bg {
    position: relative;
  }

  .mobile-navstyle .navbar-nav .dropdown-menu a {
    font-size: 3.2rem;
  }

  .fullgr .mobilestylelogo {
    background: #57c483;
  }

  .testimonials .slick-next::before,
  .testimonials .slick-prev {
    left: 4px;
  }

  .sample-divider {
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
    margin-bottom: 6rem;
  }

  .sample-divider:nth-child(2n) {
    flex-direction: inherit;
    text-align: right;
  }

  .stockpart {
    width: 80%;
    margin: auto;
  }
  .search-x.height {
    height: 585px;
  }
  .skyimg::before {
    background-size: 255px;
    right: -47px;
    top: -107px;
  }
  .cap-divider {
    flex-wrap: wrap;
  }
  .cap-divider {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cap-box .rp-box {
    margin-right: 0;
  }
  .subscribe-plan, .detail-form {
    width: 300px;
    margin: auto;
  }
  .sb-planbox {
    width: 100%;
  }
  .sb-banner-title small {
    font-size: 3rem;
  }
  .inbanner-text p.sb-banner-title {
    font-size: 6.2rem;
  }
  .smt-btn {
    font-size: 2.5rem;
    padding: 1.5rem 3rem;
    color: #fff;
  }
  .inbanner-text p.sb-banner-title {
    margin-bottom: 5rem;
  }
  .inbanner-text.sbdiv {
    width: 90%;
  }
  .subscribe-main h2 span {
    font-size: 2.8rem;
  }
  .plan-space .btn-green {
    font-size: 2.5rem;
    padding: 2rem 4rem;
  }
  .price-box {
    margin-bottom: 3rem;
  }
  .plan-space {
    padding: 1rem 2rem 6rem 2rem;
  }
  .detail-form .button {
    padding: 1rem 3.2rem;
    border-radius: 16rem;
    font-size: 2.8rem;
  }
  .perelax-menu ul {
    display: flex;
    flex-wrap: wrap;
  }
  .perelax-menu ul li {
    width: 100%;
    margin: 3rem 0;
  }
  .perelax-menu ul li a {
    font-size: 3.8rem;
  }
  .filter-pera {
    font-size: 3rem;
  }
  .perelax-menu ul li a::before {
    top: 4px;
  }
  .report-page .spacing {
    padding-top: 0;
  }
  .userdata {
    align-items: start;
  }
  .analysis {
    display: block;
  }
  .analysis img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5rem;
  }
  .navbar-brand {
    margin-right: 0;
  }
}

@media screen and (max-width: 575px) {
  .inbanner-text p {
    font-size: 2.8rem;
  }

  .height {
    height: 265px;
  }

  .banner-text {
    width: 176px;
  }

  .button {
    margin-top: 20px;
    font-size: 2rem;
    padding: 1rem 1rem
  }

  .banner-section h1 {
    line-height: 1;
  }

  .banner-section p {
    font-size: 2.5rem;
  }

  .mrg-10 {
    margin-bottom: 18px;
  }

  .mobile-logo img {
    width: 200px !important;
  }

  .banner-img img {
    width: 100%;
    max-width: 176px;
  }

  .banner-img {
    width: 44%;
    margin-top: 13rem;
  }

  .report-section h2 {
    font-size: 4rem;
  }

  .stockbox {
    margin-bottom: 0rem;
    width: 38%;
  }

  .testimonials .slick-prev::before {
    right: 32px;

  }

  .footer-smo a {
    padding: 0 2rem 0 0;
  }

  .card-body {
    padding: 1.25rem 5rem;
  }

  .testimonials .slick-prev::before,
  .testimonials .slick-next {
    right: 13px;
  }

  .contact-page h2 {
    font-size: 8rem;
  }
  .inbanner-text p.sb-banner-title {
    font-size: 4.2rem;
  }
  .basic, .conclusion {
    width: 100%;
  }
  .basic {
    font-size: 2.5rem;
  }
  .perelax-menu {
    padding: 0;
  }
  .toplogo img {
    margin: 0 1.8rem;
  }
  .toplogo {
    padding-top: 15px;
    padding-bottom: 2rem;
  }
  .filter-section .sm-btn {
    font-size: 3rem;
  }
  .basic-auto {
    width: 240px;
  }
}

@media screen and (max-width: 359px) {
  .banner-section h1 {
    font-size: 4rem;
  }

  .btn-green {
    font-size: 3rem;
  }

  .login-divider {
    width: 266px;
  }

  .divider-part {
    font-size: 2.7rem;
  }
  .subscribe-plan, .detail-form {
    width: 266px;
  } 
}